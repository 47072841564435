import { localizeAmountAndCurrency } from '%app/js/common/services/localizeMoney';

export default class CartController {
    constructor($scope, cartStorage) {
        this.$scope = $scope;
        this.cartStorage = cartStorage;
        this.showCartSummary = false;
    }

    init(parameters) {
        this.cartStorage.setSumBeforeDiscount(parameters.sum_before_discount);
        this.cartStorage.setDiscountAmount(parameters.discount_amount);
        this.cartStorage.setDiscountedSum(parameters.discounted_sum);
        this.cartStorage.setCurrency(parameters.currency);

        this.$scope.$watchGroup(
            [
                () => this.cartStorage.getSumBeforeDiscount(),
                () => this.cartStorage.getDiscountAmount(),
                () => this.cartStorage.getDiscountedSum(),
                () => this.cartStorage.getCurrency(),
            ],
            ([sumBeforeDiscount, discountAmount, discountedSum, currency]) => {
                this.sumBeforeDiscount = sumBeforeDiscount;
                this.discountAmount = discountAmount;
                this.discountedSum = discountedSum;

                this.sumBeforeDiscountLocalized = localizeAmountAndCurrency(sumBeforeDiscount, currency);
                this.discountAmountLocalized = localizeAmountAndCurrency(discountAmount, currency);
                this.discountedSumLocalized = localizeAmountAndCurrency(discountedSum, currency);
            },
        );
    }

    toggleCartSummary() {
        this.showCartSummary = !this.showCartSummary;
    }
}

CartController.$inject = [
    '$scope',
    'cartStorage',
];
