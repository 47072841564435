export default class OrderDialogModalController {
    constructor($uibModalInstance, modalBody) {
        this.$uibModalInstance = $uibModalInstance;
        this.modalBody = modalBody;
    }

    ok() {
        this.$uibModalInstance.close();
    };
}

OrderDialogModalController.$inject = [
    '$uibModalInstance',
    'modalBody'
];
