import angular from 'angular';

import EventLocation from './event-location';
import PriceTypeSelector from './price-type-selector';
import SpinnerTrigger from './spinner-trigger';
import ClearField from './clear-field';

const moduleName = 'paysera.tickets_frontend.directives';

angular.module(moduleName, [])
    .directive('eventLocation', EventLocation)
    .directive('priceTypeSelector', PriceTypeSelector)
    .directive('spinnerTrigger', SpinnerTrigger)
    .directive('clearField', ClearField)
;

export default moduleName;
