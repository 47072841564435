import balloon from '../../balloon';

export default class Balloon {
    show(seat) {
        balloon.show(seat);
    }

    remove(seat) {
        balloon.remove(seat);
    }

    updateMap(data) {
        balloon.updateMap(data);
    }
}
