import angular from 'angular';

export default class SelectedSeatPriceTypeController {
    constructor($scope, orderDetailsModificationClient, cartStorage, orderErrorsManager) {
        this.$scope = $scope;
        this.orderDetailsModificationClient = orderDetailsModificationClient;
        this.cartStorage = cartStorage;
        this.orderErrorsManager = orderErrorsManager;

        this.eventToken = null;
        this.orderDetails = null;
        this.selectedPriceType = null;
        this.priceTypes = null;
    }

    init(parameters) {
        this.eventToken = parameters.event_token;
        this.priceTypes = parameters.price_types;
        this.orderDetails = parameters.order_details;
        this.selectedPriceType = parameters.selected_price_type;

        this.$scope.$watch(
            () => { return this.selectedPriceType; },
            (newValue, oldValue) => {
                if (!angular.equals(newValue, oldValue)) {
                    this.orderDetailsModificationClient
                        .confirmPriceType(this.eventToken, this.orderDetails, this.selectedPriceType.id)
                        .then(
                            (data) => {
                                this.cartStorage.setSumBeforeDiscount(data.sum_before_discount);
                                this.cartStorage.setDiscountAmount(data.discount_amount);
                                this.cartStorage.setDiscountedSum(data.discounted_sum);
                            },
                            (error) => {
                                this.orderErrorsManager.processError(error)
                                    .then(
                                        () => {
                                            if (!angular.equals(newValue, oldValue)) {
                                                this.selectedPriceType = oldValue;
                                            }
                                        }
                                    )
                                ;
                            }
                        )
                    ;
                }
            }
        );
    }
}

SelectedSeatPriceTypeController.$inject = [
    '$scope',
    'orderDetailsModificationClient',
    'cartStorage',
    'orderErrorsManager'
];
