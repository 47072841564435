import EventLocationController from './event-location-controller';

export default function () {
    return {
        restrict: 'E',
        scope: {
            location: '=',
            apiKey: '=',
        },
        templateUrl: 'event-location.html',
        bindToController: true,
        controller: EventLocationController,
        controllerAs: 'eventLocationVM',
    };
}
