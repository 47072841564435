import jQuery from 'jquery';

class SVG {
    removeClass(obj, remove) {
        let classes = jQuery(obj).attr('class');
        const index = classes.search(remove);

        if (index === -1) {
            return false;
        }

        classes = classes.substring(0, index) + classes.substring((index + remove.length), classes.length);
        jQuery(obj).attr('class', classes);

        return true;
    }

    addClass(obj, add) {
        let classes = jQuery(obj).attr('class');
        classes = classes + ' ' + add;
        jQuery(obj).attr('class', classes);
    }

    hasClass(obj, has) {
        const classes = jQuery(obj).attr('class');
        const index = classes.search(has);

        return !(index === -1)
    }

    replaceClass(obj, target, replacement) {
        if (this.hasClass(obj, target)) {
            this.removeClass(obj, target);
            this.addClass(obj, replacement);
        } else if (this.hasClass(obj, replacement)) {
            this.removeClass(obj, replacement);
            this.addClass(obj, target);
        }
    }
}

export default new SVG();
