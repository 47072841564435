import angular from 'angular';
import seatSelection from '%evp_ticket_frontend/js/seat-selection';

export default class SeatInformationController {
    constructor($scope, seatStorage, balloon) {
        this.$scope = $scope;
        this.seatStorage = seatStorage;
        this.balloon = balloon;
    }

    init(data) {
        this.seatStorage.setSeatInfo(data);
        this.processData(data);

        this.$scope.$watch(
            () => { return this.seatStorage.getSeatInfo(); },
            (newValue, oldValue) => {
                this.processData(newValue);
            }
        );
    }

    processData(data) {
        if (data !== null) {
            this.balloon.updateMap(data);
            angular.forEach(data, (seatInfo, id) => {
                seatSelection.updateSeatById(id, seatInfo.status_class);
            })
        }
    }
}

SeatInformationController.$inject = [
    '$scope',
    'seatStorage',
    'balloon'
];
