import ResponsiveBootstrapToolkit from 'responsive-toolkit';

export default class ViewportSize
{
    is(value) {
        return ResponsiveBootstrapToolkit.is(value);
    }
}

ViewportSize.$inject = [];
