import angular from 'angular';

import ticketsFrontendApi from '../api/app';
import ticketsCommon from '%app/js/common/app';
import uiSelect from 'ui-select';
import ngSanitize from 'angular-sanitize';
import uiBootstrap from 'angular-ui-bootstrap';
import ngMap from 'ngmap';

import 'fork-adonespitogo-angular-loading-spinner/angular-loading-spinner';
import 'angular-timer';

import controllersModuleName from './controllers';
import directivesModuleName from './directives';
import servicesModuleName from './services';

function config($interpolateProvider) {
    $interpolateProvider
        .startSymbol('{[{')
        .endSymbol('}]}');
}
config.$inject = ['$interpolateProvider'];

const moduleName = 'paysera.tickets_frontend';
angular
    .module(moduleName, [
        'timer',
        uiBootstrap,
        'ngLoadingSpinner',
        ticketsFrontendApi,
        ticketsCommon,
        ngMap,
        uiSelect,
        ngSanitize,

        controllersModuleName,
        directivesModuleName,
        servicesModuleName,
    ])
    .config(config);

export default moduleName;
