import angular from 'angular';

export default class PriceTypeSelectorController {
    constructor() {
        this.showSelect = false;
        this.$onInit = this.init;
    }

    init() {
        this.showSelect = this.priceTypes.length > 1;

        angular.forEach(this.priceTypes, (value, key) => {

            if (value.default && this.selectedPriceType === null) {
                this.selectedPriceType = value;
            }
        });
        if (this.selectedPriceType === null) {
            this.selectedPriceType = this.priceTypes[0];
        }
    }
}
