import LocalizationStorage from './common/services/localizationStorage';

let language = LocalizationStorage.getCurrentLanguage();
if (language === 'en') {
    language = 'en-gb';
}

if (language === 'xk') {
    language = 'sq';
}

require('moment/locale/' + language + '.js');

const moment = require('moment-timezone');

moment.locale(language);
moment.tz.setDefault('Europe/Vilnius');

export default moment;
