import moment from '%app/js/localized-moment';

export default class Timestamp {
    static getTimestampIfValid(date) {
        if (date === null) {
            return null;
        }
        const instance = moment(date);

        if (instance.isValid()) {
            return instance.format('X');
        }
        return null;
    }

    static getTimestampFromSeconds(timestamp) {
        if (timestamp === null) {
            return null;
        }

        return timestamp * 1000;
    }

    static getDateWithDefaultTimezone(timestamp) {
        if (timestamp === null) {
            return null;
        }

        return moment(timestamp * 1000);
    }
}
