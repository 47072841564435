export default class TimerDialogModalController {
    constructor($uibModalInstance) {
        this.$uibModalInstance = $uibModalInstance;
    }

    ok() {
        this.$uibModalInstance.close();
    }
}

TimerDialogModalController.$inject = [
    '$uibModalInstance'
];
