export default class SeatStorage {
    constructor() {
        this.seat = null;
        this.seatInfo = null;
        this.toggleVisibility = null;
    }
    setSeatInfo(value) {
        this.seatInfo = value;
    }

    getSeatInfo() {
        return this.seatInfo;
    }
}
