import { angularSpinner } from 'angular-spinner';
import directivesModuleName from './directives';
import servicesModuleName from './services';
import filtersModuleName from './filters';
import spinnerConfig from '../spinner-config';

function config(usSpinnerConfigProvider) {
    usSpinnerConfigProvider.setDefaults(spinnerConfig);
}

config.$inject = ['usSpinnerConfigProvider'];


const moduleName = 'paysera.tickets.common';
angular
    .module(moduleName, [
        angularSpinner.name,
        directivesModuleName,
        servicesModuleName,
        filtersModuleName
    ])
    .config(config)
;

export default moduleName;
