import Trans from './filters/trans';
import TransChoice from './filters/trans-choice';

const moduleName = 'paysera.tickets.common.filters';

angular.module(moduleName, [])
    .filter('trans', Trans)
    .filter('transChoice', TransChoice)
;

export default moduleName;
