import PriceTypeSelectorController from './price-type-selector-controller';

export default function () {
    return {
        restrict: 'A',
        scope: {
            priceTypes: '=priceTypeSelector',
            selectedPriceType: '='
        },
        templateUrl: 'price-type-selector.html',
        bindToController: true,
        controller: PriceTypeSelectorController,
        controllerAs: 'priceVM'
    };
}
