import angular from 'angular';

import Balloon from './balloon';
import CartStorage from './cart-storage';
import OrderErrorsManager from './order-errors-manager';
import OrderPolling from './order-polling';
import PaymentClient from './payment-client';
import SeatStorage from './seat-storage';


const moduleName = 'paysera.tickets_frontend.services';

angular.module(moduleName, [])
    .service('balloon', Balloon)
    .service('cartStorage', CartStorage)
    .service('orderErrorsManager', OrderErrorsManager)
    .service('orderPolling', OrderPolling)
    .service('paymentClient', PaymentClient)
    .service('seatStorage', SeatStorage)
;

export default moduleName;
