export default class PaymentClient {
    constructor(router, $http) {
        this.router = router;
        this.$http = $http;
    }

    checkOrderPaid(token) {
        const url = this.router.generate('EvpPaymentBundle_check_order_ajax', { 'orderToken': token });
        return this.$http.get(url);
    }
}

PaymentClient.$inject = [
    'router',
    '$http'
];
