import angular from 'angular';

export default class ServerValidationDirective
{
    constructor($compile) {
        this.restrict = 'A';
        this.require = 'ngModel';

        this.$compile = $compile;
    }

    link(scope, elm, attrs, ctrl) {
        this.appendErrorBlock(elm, attrs, scope);

        scope.$watch(
            attrs.ngModel,
            () => { ServerValidationDirective.markAsValid(elm, ctrl)}
        );

        scope.$watch(
            attrs.serverResponse,
            (serverResponse) => {
                if (angular.isUndefined(serverResponse) || angular.isUndefined(serverResponse['error'])) {
                    ServerValidationDirective.markAsValid(elm, ctrl);
                    return;
                }

                if (serverResponse['error'] !== 'invalid_parameters') {
                    ServerValidationDirective.markAsValid(elm, ctrl);
                    return;
                }

                if (angular.isUndefined(serverResponse['error_properties'])) {
                    ServerValidationDirective.markAsValid(elm, ctrl);
                    return;
                }

                if (angular.isUndefined(serverResponse['error_properties'][attrs.serverValidation])) {
                    ServerValidationDirective.markAsValid(elm, ctrl);
                    return;
                }

                ctrl.$setValidity('serverValidation', false);
                ctrl.$error.serverMessages = serverResponse['error_properties'][attrs.serverValidation];
                ServerValidationDirective.getParent(elm).addClass('has-error');
            }
        );

        ctrl.$parsers.unshift(function (viewValue) {
            ServerValidationDirective.markAsValid(elm, ctrl);
            return viewValue;
        });
    }

    appendErrorBlock(element, attributes, scope) {
        const fieldPath = attributes.formPath + '[\'' + attributes.name + '\'].$error.serverMessages';
        const errorElement = (
            '<div class="has-error">'
            + '<p data-ng-repeat="message in ' + fieldPath +'" class="help-block">{[{ message }]}</p>'
            + '</div>'
        );

        ServerValidationDirective.getParent(element).append(this.$compile(errorElement)(scope));
    }

    static markAsValid(elm, ctrl) {
        ServerValidationDirective.getParent(elm).removeClass('has-error');
        ctrl.$setValidity('serverValidation', true);
        if (typeof ctrl.$error.serverMessages !== 'undefined') {
            delete ctrl.$error.serverMessages;
        }
    }

    static getParent(element) {
        const parent = element.parent();
        if (parent.hasClass('input-group')) {
            return parent.parent();
        }

        return parent;
    }

    static factory($compile) {
        ServerValidationDirective.instance = new ServerValidationDirective($compile);
        return ServerValidationDirective.instance;
    }
}

ServerValidationDirective.factory.$inject = ['$compile'];
