import { default as servicesModuleName } from './services'

function config($interpolateProvider){
    $interpolateProvider
        .startSymbol('{[{')
        .endSymbol('}]}')
    ;
}
config.$inject = ['$interpolateProvider'];

const moduleName = 'paysera.tickets.api';
angular
    .module(moduleName, [
        servicesModuleName
    ])
    .config(config)
;

export default moduleName;
