import angular from 'angular';

import OrderDetailsModificationClient from './order-details-modification-client';

const moduleName = 'paysera.tickets.api_services';

angular.module(moduleName, [])
    .service('orderDetailsModificationClient', OrderDetailsModificationClient)
;

export default moduleName;
