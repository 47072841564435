import angular from 'angular';

import CartController from './cart';
import PaymentController from './payment';
import PaymentChoiceController from './payment-choice';
import SeatInformationController from './seat-information';
import SeatSelectionController from './seat-selection';
import SelectedSeatPriceTypeController from './selected-seat-price-type';
import TicketTypeDiscountController from './ticket-type-discount';
import TimerController from './timer';

const moduleName = 'paysera.tickets_frontend.controllers';

angular.module(moduleName, [])
    .controller('CartController', CartController)
    .controller('PaymentController', PaymentController)
    .controller('PaymentChoiceController', PaymentChoiceController)
    .controller('SeatInformationController', SeatInformationController)
    .controller('SeatSelectionController', SeatSelectionController)
    .controller('SelectedSeatPriceTypeController', SelectedSeatPriceTypeController)
    .controller('TicketTypeDiscountController', TicketTypeDiscountController)
    .controller('TimerController', TimerController)
;

export default moduleName;
