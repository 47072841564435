import LanguageDirective from './directives/lang';
import ModalSpinnerDirective from './directives/modal-spinner';
import ServerValidationDirective from './directives/server-validation';
import TagOnBlurDirective from './directives/tag-on-blur';

const moduleName = 'paysera.tickets.common.directives';

angular.module(moduleName, [])
    .directive('lang', LanguageDirective.factory)
    .directive('modalSpinner', ModalSpinnerDirective.factory)
    .directive('serverValidation', ServerValidationDirective.factory)
    .directive('tagOnBlur', TagOnBlurDirective.factory)
;

export default moduleName;
