function clearField() {
    return {
        restrict: 'A',
        link: (scope, element) => {
            element
                .wrap('<div class="clear-field-wrapper"></div>')
                .after('<span class="clear-icon">×</span>');

            const clearInputElement = angular.element(element.next());

            clearInputElement.bind('click', () => {
                scope.$apply(element.val(''));
            });
        },
    };
}

export default clearField;
