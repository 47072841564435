import query from 'query-string';
import AppSettingsProvider from '%app/js/common/services/AppSettingsProvider';

export default class EventLocationController {
    constructor(NgMap) {
        this.NgMap = NgMap;
        this.apiKey = (new AppSettingsProvider()).getAppSettings().getGooglePlacesApiKey();
        this.fullMap = false;
        this.mapsUrl = `https://maps.google.com/maps/api/js?key=${this.apiKey}`;
        this.centerStaticUrl = null;
        this.cachedMapUrl = null;

        this.$onInit = this.init;
    }

    init() {
        this.marker = [
            this.location.latitude,
            this.location.longitude,
        ];
        this.cachedMapUrl = this.location.cached_map_url;

        this.NgMap.getMap('map')
            .then((map) => {
                this.map = map;
            });
        if (this.cachedMapUrl !== null) {
            this.centerStaticUrl = this.cachedMapUrl;
        } else {
            const parameters = {
                key: this.apiKey,
                center: `${this.location.latitude},${this.location.longitude}`,
                markers: [
                    `${this.location.latitude},${this.location.longitude}`,
                ],
                size: '755x300',
                scale: 2,
            };
            this.centerStaticUrl = `https://maps.googleapis.com/maps/api/staticmap?${query.stringify(parameters)}&callback=Function.prototype`;
        }
    }

    showFullMap() {
        this.fullMap = true;
    }
}

EventLocationController.$inject = ['NgMap'];
