import jQuery from 'jquery';
import SVG from './svg';

class SeatSelection {
    updateSeats(map) {
        if (map === undefined) {
            return;
        }

        jQuery('svg .seat').each((key, el) => {
            const seat = jQuery(el);
            this.updateSeatById(seat.attr('id'), map[seat.attr('id')]);
        });
    }

    updateSeatById(id, className) {
        const seat = jQuery('svg .seat#' + id);

        if (seat.length === 0) {
            return;
        }

        SVG.removeClass(seat, 'free');
        SVG.removeClass(seat, 'reserved');
        SVG.removeClass(seat, 'taken');
        SVG.addClass(seat, className);
    }

    toggleSeatVisibility(id) {
        const seat = jQuery('svg .seat#' + id);

        SVG.replaceClass(seat, 'show', 'hide');
    }

    updateVisibility(map) {
        if (map === undefined) {
            return;
        }

        jQuery('svg .seat').each((key, el) => {
            const seat = jQuery(el);
            SVG.removeClass(seat, 'show');
            SVG.removeClass(seat, 'hide');
            SVG.addClass(seat, map[seat.attr('id')]);
        });
    }
}

export default new SeatSelection();
