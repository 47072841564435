import { Money } from '@paysera/money';
import logger from '%app/js/common/services/Logger';
import { getLocalization } from '../localizeNumber';

function supportsFormatToParts(intl) {
    if (typeof intl.formatToParts !== 'function') {
        // todo: remove when Sentry records will be gone
        logger.info('Browser not supports NumberFormat.formatToParts');
        return false;
    }

    return true;
}

function getNormalizedAmount(amount, currency) {
    try {
        return Money.create(amount, currency).getAmount();
    } catch (error) {
        return null;
    }
}

function localizeMoney(money) {
    if (!money) {
        return null;
    }

    return new Intl.NumberFormat(getLocalization(), { style: 'currency', currency: money.getCurrency() })
        .format(money.getAmount());
}

function localizeAmountOnly(amount, currency) {
    if (!getNormalizedAmount(amount, currency)) {
        return null;
    }

    const intl = new Intl.NumberFormat(getLocalization(), { style: 'currency', currency });

    if (!supportsFormatToParts(intl)) {
        return amount;
    }

    return intl
        .formatToParts(getNormalizedAmount(amount, currency))
        .filter(({ type }) => type !== 'currency')
        .map(({ value }) => value)
        .reduce((string, part) => string + part)
        .trim();
}

function localizeAmountAndCurrency(amount, currency) {
    if ((!amount && amount !== 0) || !currency) {
        return null;
    }
    try {
        return localizeMoney(new Money(amount, currency));
    } catch (error) {
        return `${amount} ${currency}`;
    }
}

function localizeMoneyRange(min, max) {
    if (!max) {
        return localizeMoney(min);
    }

    const intl = new Intl.NumberFormat(getLocalization(), { style: 'currency', currency: min.getCurrency() });

    if (!supportsFormatToParts(intl)) {
        return `${min.formatAmount()} - ${max.formatAmount()} ${min.getCurrency()}`;
    }

    const currencySymbol = intl.formatToParts(min.getAmount()).find(part => part.type === 'currency').value;
    const localizedMin = intl.format(min.getAmount());
    const localizedMax = intl.format(max.getAmount());

    if (localizedMin.indexOf(currencySymbol) === 0) {
        const replacedMin = localizedMin.replace(currencySymbol, '').trim();
        const replacedMax = localizedMax.replace(currencySymbol, '').trim();

        return `${currencySymbol}${replacedMin} - ${replacedMax}`;
    }

    const replacedMin = localizedMin.replace(currencySymbol, '').trim();
    const replacedMax = localizedMax.replace(currencySymbol, '').trim();

    return `${replacedMin} - ${replacedMax} ${currencySymbol}`;
}

export {
    localizeMoney,
    localizeAmountAndCurrency,
    localizeMoneyRange,
    localizeAmountOnly,
};
