export default class ModalSpinnerManagerService
{
    constructor(usSpinnerService) {
        this.usSpinnerService = usSpinnerService;

        this.starting = 0;
        this.stopping = 0;
        this.ignoredUrlPatterns = [];
    }

    isStarting() {
        return this.starting;
    }

    isStopping() {
        return this.stopping;
    }

    start(key) {
        this.starting++;
        this.usSpinnerService.spin(key);
    }

    stop(key) {
        this.stopping++;
        this.usSpinnerService.stop(key);
    }

    addIgnoredUrlPattern(pattern) {
        if (this.ignoredUrlPatterns.indexOf(pattern) === -1) {
            this.ignoredUrlPatterns.push(pattern);
        }
    }

    getIgnoredUrlPatterns() {
        return this.ignoredUrlPatterns;
    }

    processSpinning(spinning, element) {
        if (spinning){
            element.removeClass('ng-hide');
        } else {
            element.addClass('ng-hide');
        }
    }

}

ModalSpinnerManagerService.$inject = ['usSpinnerService'];
