import angular from 'angular';

export default class ModalSpinnerDirective
{
    constructor(modalSpinnerManager, $http, usSpinnerService){
        this.restrict='EA';

        this.modalSpinnerManager = modalSpinnerManager;
        this.http = $http;
        this.usSpinnerService = usSpinnerService;
    }

    link(scope, elem, attrs, ctrl) {
        scope.$watch(
            () => {
                if (this.http.pendingRequests.length > 0) {
                    let toSkip = 0;
                    angular.forEach(this.http.pendingRequests, (request) => {
                        angular.forEach(this.modalSpinnerManager.getIgnoredUrlPatterns(), (pattern) => {
                            if (request.url.indexOf(pattern) !== -1) {
                                toSkip++;
                            }
                        })
                    });
                    return this.http.pendingRequests.length > toSkip;
                }
                return false;
            },
            (spinning) => {
                if (spinning) {
                    this.usSpinnerService.spin('spinner-default');
                } else {
                    this.usSpinnerService.stop('spinner-default');
                }
                this.modalSpinnerManager.processSpinning(spinning, elem);
            }
        );

        scope.$watch(
            () => { this.modalSpinnerManager.isStarting(); },
             (newValue) => {
                if (newValue > 0) {
                    this.modalSpinnerManager.processSpinning(true, elem);
                }
            }
        );

        scope.$watch(
            () => { return this.modalSpinnerManager.isStopping(); },
            (newValue) => {
                if (newValue > 0) {
                    this.modalSpinnerManager.processSpinning(false, elem);
                }
            }
        );
    }

    static factory(modalSpinnerManager, $http, usSpinnerService) {
        ModalSpinnerDirective.instance = new ModalSpinnerDirective(modalSpinnerManager, $http, usSpinnerService);
        return ModalSpinnerDirective.instance;
    }
}

ModalSpinnerDirective.factory.$inject = ['modalSpinnerManager', '$http', 'usSpinnerService'];
