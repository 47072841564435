import LocalizationStorage from './services/localizationStorage';
import ModalSpinnerManagerService from './services/modal-spinner-manager';
import RouterService from './services/router';
import TranslatorService from './services/translator';
import VieportSize from './services/viewport-size';
import Timestamp from './services/timestamp';

const moduleName = 'paysera.tickets.common.services';

angular.module(moduleName, [])
    .service('localizationStorage', LocalizationStorage)
    .service('modalSpinnerManager', ModalSpinnerManagerService)
    .service('router', RouterService)
    .service('translator', TranslatorService)
    .service('viewportSize', VieportSize)
    .service('timestamp', Timestamp)
;

export default moduleName;
