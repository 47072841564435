import TimerDialogModalController from './timer-dialog-modal';

export default class TimerController {
    constructor($scope, $timeout, $window, $uibModal) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$uibModal = $uibModal;
    }

    init(parameters) {
        let currentUnixTimeInMilliseconds = new Date().getTime();
        this.endExpirationTime = currentUnixTimeInMilliseconds + parameters.expireAfterInMilliseconds;

        this.listenForTimerStop(() => {
            let modalInstance = this.$uibModal.open({
                animation: true,
                backdrop: 'static',
                keyboard: false,
                controller: TimerDialogModalController,
                controllerAs: 'vm',
                templateUrl: 'timerDialogModal.html'
            });

            modalInstance.result.then(() => {
                this.redirectToEventStart(parameters);
            });
        });
    }

    listenForTimerStop(callback) {
        this.$scope.$on('timer-stopped', () => {
            this.$timeout(callback);
        });
    }

    redirectToEventStart(parameters) {
        this.$window.location.href = parameters.redirectUrl;
    }
}

TimerController.$inject = [
    '$scope',
    '$timeout',
    '$window',
    '$uibModal'
];
