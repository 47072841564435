export default class PaymentChoiceController {
    constructor($timeout, modalSpinnerManager) {
        this.$timeout = $timeout;
        this.modalSpinnerManager = modalSpinnerManager;

        this.paymentChoice = null;
        this.countryData = [];
        this.countries = [];
        this.currentCountry = null;
    }

    selectChoice(choice) {
        this.paymentChoice = choice;

        this.modalSpinnerManager.start();
        this.modalSpinnerManager.processSpinning(true, angular.element(document).find('span[data-us-spinner]'));

        this.$timeout(() => {
            jQuery('.payment-type-form').submit();
        }, 250);
    }

    init(data) {
        this.countryData = data.config;
        this.countries = Object.keys(this.countryData);
        this.currentCountry = data.default_country;

        if (!data.config.hasOwnProperty(data.default_country)) {
            this.currentCountry = data.other_countries_translated_slug;
        }
    }
}

PaymentChoiceController.$inject = [
    '$timeout',
    'modalSpinnerManager'
];
