export default class OrderPolling {
    constructor($q, $timeout, paymentClient) {
        this.$q = $q;
        this.$timeout = $timeout;
        this.paymentClient = paymentClient;

        this.pollingInterval = 5000;
    }

    start(parameters) {
        const deferred = this.$q.defer();
        const self = this;

        (function tick() {
            self.paymentClient
                .checkOrderPaid(parameters.orderToken)
                .then(
                    (data) => {
                        if (data.data.status !== 'ready') {
                            self.$timeout(tick, self.pollingInterval);
                        } else {
                            deferred.resolve(data.data);
                        }
                    },
                    (msg) => {
                        deferred.reject(msg.data);
                    }
                );
        })();

        return deferred.promise;
    }
}

OrderPolling.$inject = [
    '$q',
    '$timeout',
    'paymentClient'
];
