import localizationStorage from '%app/js/common/services/localizationStorage';

function getLocalization() {
    let localization = `${localizationStorage.getCurrentLanguage()}-${localizationStorage.getCurrentCountry()}`;
    if (localizationStorage.getCurrentCountry() === null) {
        localization = localizationStorage.getCurrentLanguage();
    }

    return localization;
}

export default getLocalization;
