export default class LanguageDirective
{
    constructor(localizationStorage){
        this.restrict='A';

        this.localizationStorage = localizationStorage;
    }

    link(scope, element, attrs) {
        this.localizationStorage.setLanguage(attrs.lang);
    }

    static factory(localizationStorage) {
        LanguageDirective.instance = new LanguageDirective(localizationStorage);
        return LanguageDirective.instance;
    }
}

LanguageDirective.factory.$inject = ['localizationStorage'];
