export default class TagOnBlurDirective
{
    constructor() {
        this.require = 'uiSelect';
    }

    link(scope, elm, attrs, ctrl) {
        ctrl.searchInput.on('blur', () => {
            if (ctrl.items.length > 0 || ctrl.tagging.isActivated) {
                ctrl.searchInput.triggerHandler('tagged');
                let newItem = ctrl.search;
                if (newItem !== '') {
                    if (ctrl.tagging.fct) {
                        newItem = ctrl.tagging.fct(newItem);
                    }
                    if (newItem) {
                        ctrl.select(newItem, true);
                    }
                }
            }
        });
    }

    static factory() {
        TagOnBlurDirective.instance = new TagOnBlurDirective();
        return TagOnBlurDirective.instance;
    }
}
