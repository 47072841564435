export default class SeatSelectionController {
    constructor(cartStorage, seatStorage, orderDetailsModificationClient, orderErrorsManager) {
        this.cartStorage = cartStorage;
        this.seatStorage = seatStorage;
        this.orderDetailsModificationClient = orderDetailsModificationClient;
        this.orderErrorsManager = orderErrorsManager;

        this.cartItems = null;
        this.eventToken = null;
        this.sector = null;
    }

    init(parameters) {
        this.eventToken = parameters.event_token;
        this.sector = parameters.sector;
        this.cartItems = parameters.cart_items;
    }

    toggleSeat(seatId) {
        this.orderDetailsModificationClient
            .toggleSeat(this.eventToken, this.sector, seatId)
            .then(
                (data) => {
                    this.cartItems = data.cart_items;

                    this.cartStorage.setSumBeforeDiscount(data.cart.sum_before_discount);
                    this.cartStorage.setDiscountAmount(data.cart.discount_amount);
                    this.cartStorage.setDiscountedSum(data.cart.discounted_sum);

                    this.seatStorage.setSeatInfo(data.seat_info);
                },
                (data) => {
                    this.orderErrorsManager.processError(data);
                    this.seatStorage.setSeatInfo(data.seat_info);
                })
        ;
    }
}

SeatSelectionController.$inject = [
    'cartStorage',
    'seatStorage',
    'orderDetailsModificationClient',
    'orderErrorsManager'
];
