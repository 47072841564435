import OrderDialogModalController from '../controllers/order-dialog-modal';

export default class OrderErrorsManager {
    constructor($uibModal, $window) {
        this.$uibModal = $uibModal;
        this.$window = $window;
    }

    processError(error) {
        const errorData = error.data;
        const modalInstance = this.$uibModal.open({
            animation: true,
            backdrop: 'static',
            keyboard: false,
            controller: OrderDialogModalController,
            controllerAs: 'orderDialogVM',
            templateUrl: 'orderDialogModal.html',
            resolve: {
                modalBody: () => { return errorData.error_message; }
            }
        });

        return modalInstance.result.then(() => {
            if (errorData.reload_page) {
                this.$window.location.reload(true);
            }
        });
    }
}

OrderErrorsManager.$inject = [
    '$uibModal',
    '$window'
];
