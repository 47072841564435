function spinnerTrigger(modalSpinnerManager) {
    return {
        restrict: 'A',
        scope: true,
        link: (scope, element) => {
            element.on('click', () => {
                modalSpinnerManager.start();
                modalSpinnerManager.processSpinning(true, angular.element(document).find('span[data-us-spinner]'));
            });
        },
    };
}

spinnerTrigger.$inject = ['modalSpinnerManager'];

export default spinnerTrigger;
