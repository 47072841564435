import jQuery from 'jquery';
import 'bootstrap/dist/js/bootstrap';

class Balloon {
    constructor() {
        this.container = 'script#seat-info-popover\\.html';
        this.template = null;
        this.map = null;
        this.target = 'svg .seat';
    }

    show(seat) {
        if (!this.map || seat.data('bs.popover')) {
            return;
        }

        const properties = this.map[seat.attr('id')];
        const text = this.template
            .replace('{row}', properties.row)
            .replace('{col}', properties.col)
            .replace('{price}', properties.price)
            .replace('{status}', properties.status)
        ;
        seat
            .popover({
                content: text,
                html: true,
                placement: 'top',
                trigger: 'manual',
                container: 'body',
                title: properties.ticket_type,
                animation: false
            })
            .popover('show')
        ;

        const popoverElement = jQuery('.popover');
        popoverElement.css('top', popoverElement.position().top + jQuery(document).scrollTop());

        seat.data('bs.popover').created_by = 'balloon';
    }

    remove(seat) {
        const popover = seat.data('bs.popover');
        if (popover !== undefined && popover.created_by === 'balloon') {

            const popoverElement = jQuery('.popover');
            popoverElement.css('top', popoverElement.position().top - jQuery(document).scrollTop());

            seat.popover('destroy');
        }
    }

    updateMap(data) {
        if (data === undefined) {
            return;
        }

        this.map = data;
    }

    init() {
        this.template = jQuery(this.container).html();
        const self = this;
        jQuery('body')
            .on('mouseenter', this.target, null, function() {
                self.show(jQuery(this));
            })
            .on('mouseleave', this.target, null, function() {
                self.remove(jQuery(this));
            })
        ;
    }
}

const balloon = new Balloon();

jQuery(document).ready(() => {
    balloon.init();
});

export default balloon;
