export default class OrderDetailsModificationClient {
    constructor(router, $http) {
        this.router = router;
        this.http = $http;
    }

    modifyOrderDetails(eventToken, ticketType, priceType, count) {
        const url = this.router.generate('evp_ticket_bundle.step.ticket_type_discount.modify_order_details', {
            'eventToken': eventToken,
            'ticketTypeId': ticketType
        });
        return this.http
            .post(url, {
                'price_type': priceType,
                'count': count
            })
            .then(function (response) {
                return response.data;
            })
        ;
    }

    confirmPriceType(eventToken, orderDetails, priceType) {
        const url = this.router.generate('evp_ticket_bundle.step.seat_selection.confirm_price_type', {
            'eventToken': eventToken
        });
        return this.http
            .post(url, {
                'order_details': orderDetails,
                'price_type': priceType
            })
            .then(function (response) {
                return response.data;
            })
        ;
    }

    toggleSeat(eventToken, sector, seat) {
        const url = this.router.generate('evp_ticket_bundle.step.seat_selection.reserve_seat', {
            'eventToken': eventToken,
            'sectorId': sector
        });

        return this.http
            .post(url, {
                'seat': seat
            })
            .then(function (response) {
                return response.data;
            })
        ;
    }
}

OrderDetailsModificationClient.$inject = [
    'router',
    '$http'
];
