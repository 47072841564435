import Translator from '%app/js/common/services/translator';

export default function () {
    return function (text, count, placeholder, domain) {
        let translated = null;

        if (typeof text !== 'string') {
            translated = text;
        }

        if (typeof Translator !== 'object') {
            translated = text;
        }

        if (typeof count !== 'number' && parseInt(count) !== count) {
            count = 0
        }

        if (typeof placeholder !== 'object') {
            placeholder = {};
        }

        if (typeof domain === 'string' && domain.length !== 0) {
            translated = Translator.transChoice(text, placeholder, count, domain);
        } else {
            translated = Translator.transChoice(text, placeholder, count);
        }

        return translated;
    };
}
