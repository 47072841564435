export default class CartStorage {
    constructor() {
        this.sumBeforeDiscount = 0;
        this.discountAmount = 0;
        this.discountedSum = 0;
        this.currency = null;
    }

    getSumBeforeDiscount() {
        return this.sumBeforeDiscount;
    }

    getDiscountAmount() {
        return this.discountAmount;
    }

    getDiscountedSum() {
        return this.discountedSum;
    }

    setSumBeforeDiscount(value) {
        this.sumBeforeDiscount = value;
    }

    setDiscountAmount(value) {
        this.discountAmount = value;
    }

    setDiscountedSum(value) {
        this.discountedSum = value;
    }

    setCurrency(value) {
        this.currency = value;
    }

    getCurrency() {
        return this.currency;
    }
}
