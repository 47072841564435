export default class PaymentController {
    constructor(orderPolling) {
        this.orderPolling = orderPolling;

        this.errorDescription = null;
        this.errorTitle = null;
        this.errorOccured = false;

        this.areTicketsGenerated = false;
        this.tickets = [];

        this.downloadMultipleUrl = null;
        this.downloadMultipleHtml = null;
    }

    init(parameters) {
        this.orderPolling.start(parameters).then(
            (data) => {
                this.areTicketsGenerated = true;
                this.tickets = data.tickets;
                this.downloadMultipleUrl = data.multiple_url;
                this.downloadMultipleHtml = data.hasOwnProperty('multiple_html') ? data.multiple_html : null;
            },
            (data) => {
                this.errorOccured = true;
                this.errorTitle = data.error;
                this.errorDescription = data.description;
            }
        );
    }
}

PaymentController.$inject = [
    'orderPolling'
];
