import angular from 'angular';
import { localizeAmountAndCurrency } from '%app/js/common/services/localizeMoney';

export default class TicketTypeDiscountController {
    constructor($scope, orderDetailsModificationClient, cartStorage, orderErrorsManager) {
        this.$scope = $scope;
        this.orderDetailsModificationClient = orderDetailsModificationClient;
        this.cartStorage = cartStorage;
        this.orderErrorsManager = orderErrorsManager;

        this.totalSum = 0;
        this.eventToken = null;
        this.ticketType = null;
        this.ticketsCount = 0;
        this.selectedPriceType = null;
        this.priceTypes = null;
        this.availableTicketsTypesCount = null;
        this.ticketCountTouched = false;
    }

    init(parameters) {
        this.eventToken = parameters.event_token;
        this.ticketType = parameters.ticket_type;
        this.ticketsCount = parameters.tickets_count;
        this.priceTypes = parameters.price_types;
        this.selectedPriceType = parameters.selected_price_type;
        this.availableTicketsTypesCount = parameters.available_tickets_types_count;
        this.leastTicketsQuantityAvailable = parameters.least_tickets_quantity_available;

        this.$scope.$watchGroup(
            [
                () => this.selectedPriceType,
                () => this.ticketsCount,
            ],
            (newValues, oldValues) => {
                this.recalculatePrice();
                this.setDefaultTicketsCount();

                if (oldValues[0] === null && newValues[1] === oldValues[1]) {
                    return;
                }

                if (!angular.equals(newValues[0], oldValues[0]) || !angular.equals(newValues[1], oldValues[1])) {
                    this.orderDetailsModificationClient
                        .modifyOrderDetails(
                            this.eventToken,
                            this.ticketType,
                            this.selectedPriceType.id,
                            this.ticketsCount,
                        )
                        .then(
                            (data) => {
                                this.cartStorage.setSumBeforeDiscount(data.sum_before_discount);
                                this.cartStorage.setDiscountAmount(data.discount_amount);
                                this.cartStorage.setDiscountedSum(data.discounted_sum);
                            },
                            (error) => {
                                this.orderErrorsManager.processError(error)
                                    .then(
                                        () => {
                                            if (!angular.equals(newValues[1], oldValues[1])) {
                                                this.ticketsCount = oldValues[1];
                                            }
                                        },
                                    );
                            },
                        );
                }
            },
        );
    }

    recalculatePrice() {
        if (this.ticketsCount !== null && this.selectedPriceType !== null && this.selectedPriceType !== undefined) {
            this.totalSum = localizeAmountAndCurrency(
                this.ticketsCount * this.selectedPriceType.price,
                this.cartStorage.getCurrency(),
            );
        }
    }

    setDefaultTicketsCount() {
        if (!this.ticketCountTouched && this.ticketsCount === 0 && this.availableTicketsTypesCount === 1) {
            this.ticketsCount = this.leastTicketsQuantityAvailable;
        }

        this.ticketCountTouched = true;
    }
}

TicketTypeDiscountController.$inject = [
    '$scope',
    'orderDetailsModificationClient',
    'cartStorage',
    'orderErrorsManager',
];
