import angular from 'angular';
import ticketsFrontend from './tickets_frontend/app.js';

function config($interpolateProvider){
    $interpolateProvider
        .startSymbol('{[{')
        .endSymbol('}]}')
    ;
}
config.$inject = ['$interpolateProvider'];

const moduleName = 'paysera.tickets_frontend_loader';
angular
    .module(moduleName, [
        ticketsFrontend
    ])
    .config(config)
;

export default moduleName;
